
import {
  defineComponent, onMounted, PropType, ref,
} from 'vue';
import {
  Chart, ChartData, ChartOptions, ChartType, registerables,
} from 'chart.js';
import { numberWithSpaces } from '@/utils';

export default defineComponent({
  props: {
    type: { required: true, type: String as PropType<ChartType> },
    chartData: { required: true, type: Object as PropType<ChartData> },
    options: { required: true, type: Object as PropType<ChartOptions> },
    total: { required: false, type: Number },
  },
  setup(props) {
    const canvas = ref<HTMLCanvasElement | undefined>(undefined);

    onMounted(() => {
      if (canvas.value) {
        const ctx = canvas.value.getContext('2d');
        if (ctx) {
          Chart.register(...registerables);
          // eslint-disable-next-line no-new
          new Chart(ctx, {
            type: props.type,
            data: props.chartData,
            options: props.options,
          });
        }
      }
    });

    return { canvas, numberWithSpaces };
  },
});
