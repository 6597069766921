
import {
  computed,
  defineComponent, reactive, watch,
} from 'vue';
import { useStore } from 'vuex';
import { ReportKkmByActivityRow, ReportKkmRow } from '@/types/Reports';
import api from '@/api';
import useFilters from '@/modules/useFilters';
import useChoiceTypes from '@/modules/useChoiceTypes';
import useActivityTypes from '@/modules/useActivityTypes';
import useUgns, { UgnsOption } from '@/modules/useUgns';
import {
  Chart, ChartData, FontSpec, TooltipModel,
} from 'chart.js';
import { getRandomColor, getGroupColors } from '@/utils/randomColor';
import DoughnutChart from '@/views/reports/components/charts/DoughnutChart.vue';
import useIsKkm from '@/modules/useIsKkm';
import { toFont } from 'chart.js/helpers';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  components: {
    DoughnutChart,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const doughnutChartOptions = {
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          // Disable the on-canvas tooltip
          enabled: false,

          external(context: { chart: Chart; tooltip: TooltipModel<'doughnut'> }) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem: { before: string[]; lines: string[]; after: string[] }) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || [];
              const bodyLines = tooltipModel.body.map(getBody);

              let innerHtml = '<thead>';

              titleLines.forEach((title: string) => {
                innerHtml += `<tr><th>${title}</th></tr>`;
              });
              innerHtml += '</thead><tbody>';

              bodyLines.forEach((body, i: number) => {
                const colors = tooltipModel.labelColors[i];
                let style = `background:${colors.backgroundColor}`;
                style += `; border-color:${colors.borderColor}`;
                style += '; border-width: 2px';
                style += '; width: 1.2rem; height: 1.2rem';
                style += '; display: inline-block';
                style += '; vertical-align: middle';
                style += '; margin-right: 1rem;';
                style += '; margin-top: -0.2rem;';
                const span = `<span style="${style}"></span>`;
                innerHtml += `<tr><td>${span}${body}</td></tr>`;
              });
              innerHtml += '</tbody>';

              const tableRoot = tooltipEl.querySelector('table');
              if (tableRoot) {
                tableRoot.innerHTML = innerHtml;
              }
            }

            const position = context.chart.canvas.getBoundingClientRect();
            const bodyFont = toFont(tooltipModel.options.bodyFont as Partial<FontSpec>);

            // Display, position, and set styles for font
            tooltipEl.style.opacity = '1';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
            tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
            tooltipEl.style.font = bodyFont.string;
            tooltipEl.style.padding = `${tooltipModel.options.padding}px ${tooltipModel.options.padding}px`;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.width = 'auto';
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.8)';
            tooltipEl.style.border = '1px solid rgba(0, 0, 0, 0)';
            tooltipEl.style.color = '#fff';
          },
        },
      },
      cutout: '70%',
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          ticks: {
            display: false,
          },
        },
        y: { stacked: false },
      },
      plugins: { legend: { display: false } },
    };

    const { isChoiceType: isChoiceTypeSelector } = useChoiceTypes();

    const { activityTypes } = useActivityTypes();
    activityTypes.fetch(isChoiceTypeSelector.selected.value);

    const { ugns: ugnsSelector } = useUgns();
    ugnsSelector.fetch();

    const { isKkm: isKkmSelector } = useIsKkm();

    const totals = reactive({
      total: 3,
      chartData: {
        labels: ['С ККМ', 'Без ККМ'],
        datasets: [{ data: [1, 2], backgroundColor: ['#fd0d9a', '#0dff00'], barPercentage: 0.5 }],
      } as ChartData,
    });

    interface CurrentFilters extends Record<string, unknown>{
      isChoiceType: boolean | null | undefined
      activityId: number | null
      ugnsId: number | null
      locality: string
      isKkm: boolean | null
      inspector: string
    }
    const report = useFilters({
      filters: {
        isChoiceType: null,
        activityId: null,
        ugnsId: null,
        locality: '',
        isKkm: null,
        inspector: '',
      } as CurrentFilters,
      immediateFilters: ['isChoiceType', 'activityId', 'ugnsId', 'isKkm'],
      delayedFilters: ['locality', 'inspector'],
      rows: [] as ReportKkmRow[] | ReportKkmByActivityRow[],
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Всего НП', data: [], backgroundColor: [], barPercentage: 0.5, borderWidth: 2, borderColor: '#000', borderSkipped: false,
          },
          {
            label: 'НП с ККМ', data: [], backgroundColor: [], barPercentage: 0.5, borderWidth: 2, borderColor: '#000', borderSkipped: false,
          },
          {
            label: 'НП без ККМ', data: [], backgroundColor: [], barPercentage: 0.5, borderWidth: 2, borderColor: '#000', borderSkipped: false,
          },
        ],
      } as ChartData,
      async fetchContents() {
        const {
          isChoiceType, activityId, ugnsId, locality,
        } = (report.filters as CurrentFilters);
        if (!activityId) {
          const params = {
            ...activityId && { activityId },
            ...ugnsId && { ugnsId },
            ...locality && { locality },
          };

          const {
            // eslint-disable-next-line no-shadow
            resultList: rows, totalKkm, totalNalogPayers, totalNoKkm,
          } = (await api.reports.getKkm(isChoiceType == null ? true : isChoiceType, params)).data;

          // для круглого графика
          totals.total = totalNalogPayers;
          totals.chartData.datasets[0].data = [totalKkm, totalNoKkm];

          // для прямого графика
          const chartData = {
            labels: [],
            datasets: [
              {
                label: 'НП без ККМ',
                data: [],
                backgroundColor: [],
                barPercentage: 0.5,
                borderWidth: 1,
                borderColor: '#ff5c5c',
              },
              {
                label: 'НП с ККМ',
                data: [],
                backgroundColor: [],
                barPercentage: 0.5,
                borderWidth: 1,
                borderColor: '#028a8f',
              },
              {
                label: 'Всего НП',
                data: [],
                backgroundColor: [],
                barPercentage: 0.5,
                borderWidth: 1,
                borderColor: '#028a8f',
              },
            ],
          } as ChartData;
          const notSortedChartData: { name: string; countNoKkm: number; countKkm: number; countNalogPayers: number; }[] = [];
          rows.forEach((row) => {
            // eslint-disable-next-line no-shadow
            const { countNoKkm, countKkm, countNalogPayers } = row;
            notSortedChartData.push({
              name: row.activityType.value || 'Неизвестно',
              countKkm,
              countNalogPayers,
              countNoKkm,
            });
          });
          notSortedChartData.sort((a, b) => {
            if (a.countNalogPayers < b.countNalogPayers) return 1;
            if (a.countNalogPayers > b.countNalogPayers) return -1;
            return 0;
          }).splice(29);
          notSortedChartData.forEach((el, i, a) => {
            if (chartData.labels) {
              chartData.labels.push(el.name);
            }
            chartData.datasets[2].data.push(el.countNalogPayers);
            chartData.datasets[1].data.push(el.countKkm);
            chartData.datasets[0].data.push(el.countNoKkm);
            const color = getRandomColor(i, a.length);
            const groupColors = getGroupColors(3, color.toString());
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-unused-expressions
            chartData.datasets[0]?.backgroundColor?.push(groupColors[0]);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-unused-expressions
            chartData.datasets[1]?.backgroundColor?.push(groupColors[1]);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-unused-expressions
            chartData.datasets[2]?.backgroundColor?.push(groupColors[2]);
          });
          return { rows, chartData };
        // eslint-disable-next-line no-else-return
        } else {
          const filters = (report.filters as CurrentFilters);
          const { inspector, isKkm } = filters;
          const params = {
            ...ugnsId && { ugnsId },
            ...locality && { locality },
            ...typeof isKkm === 'boolean' && { isKkm },
            ...inspector && { inspector },
          };
          const rows = (await api.reports.getKkmByActivity(isChoiceType == null ? true : isChoiceType, activityId, params)).data;
          return { rows };
        }
      },
      async downloadExcel() {
        const {
          activityId, ugnsId, locality,
        } = (report.filters as CurrentFilters);
        if (!activityId) {
          const params = {
            ...activityId && { activityId },
            ...ugnsId && { ugnsId },
            ...locality && { locality },
          };
          await api.reports.getExcelKkm(report.filters.isChoiceType, params);
        } else {
          const filters = (report.filters as CurrentFilters);
          const { inspector, isKkm } = filters;
          const params = {
            ...ugnsId && { ugnsId },
            ...locality && { locality },
            ...typeof isKkm === 'boolean' && { isKkm },
            ...inspector && { inspector },
          };
          await api.reports.getExcelKkmByActivity(report.filters.isChoiceType, activityId, params);
        }
      },
    });

    if (store.state.auth.isTno) {
      watch(() => ugnsSelector.options, () => {
        const userId = store.state.auth.userInfo.ugnsTno.id;
        report.filters.ugnsId = ugnsSelector.options.find((option: UgnsOption) => option.id === userId)?.id || null;
      });
    }

    // eslint-disable-next-line no-unused-expressions

    const colsList = [
      {
        label: 'Вид деятельности',
        // display: (row: ReportKkmRow) => row.activityType.value,
        component: (row: ReportKkmRow) => ({
          name: 'a',
          href: '#',
          class: 'link',
          value: row.activityType.value,
          onClick(e: MouseEvent) {
            e.preventDefault();
            // eslint-disable-next-line no-use-before-define
            filterToQuery({ activityId: row.activityType.id || 0 });
          },
        }),
      },
      {
        label: 'Всего НП',
        width: '10%',
        display: (row: ReportKkmRow) => row.countNalogPayers,
      },
      {
        label: 'НП с ККМ',
        width: '10%',
        display: (row: ReportKkmRow) => row.countKkm,
      },
      {
        label: 'НП без ккм',
        width: '10%',
        display: (row: ReportKkmRow) => row.countNoKkm,
      },
    ];
    const colsDetail = [
      {
        label: 'Наименование субъекта',
        component: (row: ReportKkmByActivityRow) => ({
          name: 'router-link',
          value: row.name,
          class: 'link',
          to: { name: 'registry-tax-payers-payer-id', params: { payerId: row.id } },
        }),
      },
      {
        label: 'ИНН',
        display: (row: ReportKkmByActivityRow) => row.inn,
      },
      {
        label: 'Адрес',
        display: (row: ReportKkmByActivityRow) => row.address,
      },
      {
        label: 'УГНС',
        display: (row: ReportKkmByActivityRow) => row.ugnsTno,
      },
      {
        label: 'ФИО инспектора',
        display: (row: ReportKkmByActivityRow) => row.inspector?.fio || 'Не назначен',
      },
    ];
    const isDetail = computed(() => !!(report.filters as CurrentFilters).activityId);
    const cols = computed(() => (isDetail.value ? colsDetail : colsList));

    function filterFromQuery() {
      const {
        isChoice, activityId, ugnsId, isKkm, locality, inspector,
      } = route.query as { isChoice?: string, activityId?: string, ugnsId?: string, isKkm?: string, locality?: string, inspector?: string};
      const filters: Partial<CurrentFilters> = {
        isChoice: isChoice ? (JSON.parse(isChoice) as boolean) : null, activityId: activityId ? +activityId : null, ugnsId: ugnsId ? +ugnsId : null, isKkm: isKkm ? (JSON.parse(isKkm) as boolean) : null, locality: locality || '', inspector: inspector || '',
      };
      Object.assign(report.filters, filters);
      if (!report.fetching) report.fetch();
    }
    watch(
      () => [route.query],
      filterFromQuery,
      { immediate: true },
    );
    function filterToQuery(filters: {isChoice?: boolean | null, activityId?: number | null, locality?: string, ugnsId?: number | null, isKkm?: boolean | null, inspector?: string | null }) {
      // добавляет в query переданные значения
      // удаляет из query значения, которые переданы как null | void
      // eslint-disable-next-line no-param-reassign
      const method = Object.keys(filters).includes('activityId') ? 'push' : 'replace';
      if (Object.keys(filters).includes('isChoice')) {
        report.filters.isChoiceType = filters.isChoice;
        activityTypes.fetch(filters.isChoice);
      }
      router[method]({ query: { ...route.query, ...filters as Record<string, string | undefined> } });
    }

    return {
      doughnutChartOptions,
      totals,
      cols,
      isDetail,
      report,
      isChoiceTypeSelector,
      activityTypes,
      ugnsSelector,
      isKkmSelector,
      options,

      filterToQuery,
    };
  },
});
