
import {
  computed, defineComponent, reactive, watch,
} from 'vue';
import { useStore } from 'vuex';
import { ReportRegimeUgnsRow } from '@/types/Reports';
import api from '@/api';
import useFilters from '@/modules/useFilters';
import useUgns, { UgnsOption } from '@/modules/useUgns';
import useNalogRegime, { NalogRegimeOption } from '@/modules/useNalogRegime';
import { ChartData } from 'chart.js';
import { getRandomColor } from '@/utils/randomColor';
import DoughnutChart from '@/views/reports/components/charts/DoughnutChart.vue';

export default defineComponent({
  components: {
    DoughnutChart,
  },
  setup() {
    const { ugns: ugnsSelector } = useUgns();
    ugnsSelector.fetch();

    const store = useStore();

    const { nalogRegimeSelector } = useNalogRegime();
    nalogRegimeSelector.fetch();

    interface CurrentFilters extends Record<string, unknown>{
      ugnsId: UgnsOption,
      nalogRegimeId: NalogRegimeOption,
      locality: string,
    }

    const ugnsColumns = reactive({
      values: [] as Record<string, unknown>[],
    });

    interface RowWithoutChildren {
      name: string
      type: string
      subType: string
      ugns: ReportRegimeUgnsRow['ugns']
      sumNalogPayers: string | number
      hideChildren?: boolean
    }
    interface RowWithChildren extends RowWithoutChildren {
      children: (RowWithChildren | RowWithoutChildren)[]
    }
    interface RowWithOptionalChildren extends RowWithoutChildren {
      children?: (RowWithChildren | RowWithoutChildren)[]
    }

    const report = useFilters({
      filters: {
        ugnsId: ugnsSelector.selected,
        nalogRegimeId: nalogRegimeSelector.selected,
        locality: '',
      } as CurrentFilters,
      immediateFilters: ['ugnsId', 'nalogRegimeId'],
      delayedFilters: ['locality'],
      rows: [] as ReportRegimeUgnsRow[],
      chartData: {
        labels: [],
        datasets: [{ data: [], backgroundColor: [], barPercentage: 0.5 }],
      } as ChartData,
      getParams() {
        const { ugnsId: ugns, nalogRegimeId: nalogRegime, locality } = (report.filters as CurrentFilters);
        const ugnsId = ugns?.id || 0;
        const nalogRegimeId = nalogRegime?.id || 0;
        const params = {
          ...ugnsId && { ugnsId },
          ...nalogRegimeId && { nalogRegimeId },
          ...locality && { locality },
        };
        return params;
      },
      async fetchContents() {
        const response = (await api.reports.getRegimeUgns(report.getParams())).data;

        const notSortedChartData: {name: string; value: number}[] = [];
        const chartData = {
          labels: [],
          datasets: [{ data: [], backgroundColor: [], barPercentage: 0.5 }],
        } as ChartData;

        // добавить нужные колонки
        ugnsColumns.values = [];
        response.forEach((row) => {
          // eslint-disable-next-line no-shadow
          row.ugns.forEach((ugns) => {
            const { name } = ugns.ugnsTno;
            if (ugnsColumns.values.some((col) => col.label === name)) return;
            ugnsColumns.values.push({
              label: name,
              // eslint-disable-next-line no-shadow
              display: (activityRow: ReportRegimeUgnsRow) => activityRow.ugns.find((ugns) => ugns.ugnsTno.name === name)?.countNalogPayers || '',
            });
          });
        });
        // сгруппировать по уровням и развернуть обратно отсортированное
        const level1: RowWithChildren[] = [];
        const level2: RowWithChildren[] = [];
        const level3: RowWithoutChildren[] = [];
        response.forEach((a) => {
          // eslint-disable-next-line no-shadow
          const { sumNalogPayers, ugns, nalogRegime: { name, type, subType } } = a;
          const res = {
            name, type, subType, sumNalogPayers, ugns,
          };

          notSortedChartData.push({ name: subType || type || name, value: sumNalogPayers });

          if (name && !type && !subType) level1.push({ ...res, children: [] });
          if (name && type && !subType) level2.push({ ...res, children: [] });
          if (name && type && subType) level3.push(res);
        });
        level3.forEach((a) => {
          const { name, type } = a;
          // найти или создать элемент и вернуть его же
          const parent = level2.find((b) => type === b.type && name === b.name) || level2[level2.push({
            name, type, subType: '', sumNalogPayers: '', ugns: [], children: [],
          }) - 1];
          if (!parent.children) parent.children = [];
          parent.children.push(a);
        });
        level2.forEach((a) => {
          const { name } = a;
          // найти или создать элемент и вернуть его же
          const parent = level1.find((b) => name === b.name) || level1[level1.push({
            name, type: '', subType: '', sumNalogPayers: '', ugns: [], children: [],
          }) - 1];
          if (!parent.children) parent.children = [];
          parent.children.push(a);
        });
        const rows: RowWithoutChildren[] = level1.map(function parse({
          // eslint-disable-next-line no-shadow
          name, type, subType, sumNalogPayers, ugns, children = [],
        }: RowWithOptionalChildren): RowWithoutChildren[] {
          // eslint-disable-next-line no-prototype-builtins
          const hasChildren = !!(children && children.length);
          return [
            {
              name, type, subType, sumNalogPayers, ugns, ...hasChildren && { hideChildren: false },
            },
            ...hasChildren ? [children.map(parse)].flat(1) : [],
          ].flat(1);
        }).flat(1);

        notSortedChartData.sort((a, b) => {
          if (a.value < b.value) return 1;
          if (a.value > b.value) return -1;
          return 0;
        });
        notSortedChartData.slice(0, 29).forEach((elem, i, a) => {
          // eslint-disable-next-line no-unused-expressions
          chartData.labels && chartData.labels.push(elem.name);
          chartData.datasets[0].data.push(elem.value);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line
          chartData && chartData.datasets[0] && chartData.datasets[0].backgroundColor && chartData.datasets[0].backgroundColor.push(getRandomColor(i, a.length));
        });
        // if (rows.length > 1) rows.unshift(summaryRow);
        return { rows, chartData };
      },
      async downloadExcel() {
        await api.reports.getExcelRegimeUgns(report.getParams());
      },
    });
    report.fetch();

    if (store.state.auth.isTno) {
      watch(() => ugnsSelector.options, () => {
        const userId = store.state.auth.userInfo.ugnsTno.id;
        report.filters.ugnsId = ugnsSelector.options.find((option: UgnsOption) => option.id === userId) || {} as UgnsOption;
      });
    }

    const colsSlider = reactive({
      index: 0,
      visibleCount: 4,
      getVisibleCols(): Record<string, unknown>[] {
        const ugnsCols = ugnsColumns.values;
        const { length } = ugnsCols;
        if (length <= this.visibleCount) return ugnsCols;

        const result = ugnsCols.slice(this.index, this.index + this.visibleCount).map((row) => ({
          ...row, width: '12%', slideLeft: false, slideRigth: false,
        }));

        if (result[0] && this.index > 0) result[0].slideLeft = true;

        const lastIndex = this.visibleCount - 1;
        if (result[lastIndex]) result[lastIndex].slideRigth = true;

        return result;
      },
    });

    const cols = computed(() => {
      const result = [
        {
          label: 'Вид деятельности',
          width: '40%',
          display: (row: RowWithoutChildren) => (row.subType ? `<div style="padding-left: 6rem">${row.subType}</div>` : '')
          || (row.type ? `<div style="padding-left: 3rem">${row.type}</div>` : '')
          || row.name,
        },
        {
          label: '',
          width: '10%',
          // eslint-disable-next-line no-nested-ternary
          display: (row: RowWithoutChildren) => (typeof row.hideChildren === 'boolean' ? (row.hideChildren ? '<div class="ui-table__arrow ui-table__arrow--bottom"></div>' : '<div class="ui-table__arrow ui-table__arrow--top"></div>') : ''),
        },
        ...colsSlider.getVisibleCols(),
        {
          label: 'Итого НП',
          width: '12%',
          display: (row: RowWithoutChildren) => row.sumNalogPayers,
        },
      ];
      return result;
    });

    function onRowClick(e: RowWithoutChildren) {
      // eslint-disable-next-line no-prototype-builtins
      if (!e.hasOwnProperty('hideChildren')) return;
      e.hideChildren = !e.hideChildren;
    }

    const hiddenRows = computed(() => (report.rows as RowWithoutChildren[])
      .filter((item) => item.hideChildren)
      .map(({ name, type, subType }) => [name, type, subType].filter((a) => a)));

    const reportFiltered = computed(() => (report.rows as RowWithoutChildren[])
      .filter(({ name, type, subType }) => {
        const current = [name, type, subType].filter((a) => a);
        return !hiddenRows.value.some((hidden) => hidden.length < current.length && current.join().includes(hidden.join()));
      }));

    return {
      ugnsSelector,
      nalogRegimeSelector,
      cols,
      colsSlider,
      report,
      onRowClick,
      hiddenRows,
      reportFiltered,
    };
  },
});
