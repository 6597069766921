import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/ic-arrow-back.svg'


const _hoisted_1 = { class: "page__header" }
const _hoisted_2 = { class: "page__header-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "page__header-back",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.titles[_ctx.$route.query?.reportName]), 1),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$route.query?.reportName)))
    ])
  ], 64))
}