import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "page__controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiInput = _resolveComponent("UiInput")!
  const _component_UiSelector = _resolveComponent("UiSelector")!
  const _component_DoughnutChart = _resolveComponent("DoughnutChart")!
  const _component_UiTable = _resolveComponent("UiTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UiInput, {
        modelValue: _ctx.report.filters.locality,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.report.filters.locality) = $event)),
        placeholder: "Область / город"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_UiSelector, {
        placeholder: "Выбрать УГНС",
        options: _ctx.ugnsSelector.options,
        modelValue: _ctx.report.filters.ugnsId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.report.filters.ugnsId) = $event)),
        disabled: _ctx.$store.state.auth.isTno
      }, null, 8, ["options", "modelValue", "disabled"]),
      _createElementVNode("a", {
        class: "reports__download",
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.report.downloadExcel()), ["prevent"]))
      }, "Выгрузить отчёт в формате Excel")
    ]),
    (_ctx.report.rows.length > 3)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["page__charts", {'page__charts--short': _ctx.report.rows.length <= 3}])
        }, [
          (!_ctx.report.fetching)
            ? (_openBlock(), _createBlock(_component_DoughnutChart, {
                key: 0,
                class: "bar-chart",
                chartData: _ctx.report.chartData,
                type: "bar",
                options: {responsive: true, scales: { x: {ticks: {display: false}} }, maintainAspectRatio: false, plugins: { legend: {display: false}}, cutout: '70%', elements: {arc: {borderWidth: 0}}}
              }, null, 8, ["chartData"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_UiTable, {
      cols: _ctx.cols,
      rows: _ctx.report.rows,
      "empty-banner-text": _ctx.report.fetching ? 'Идет загрузка' : 'Нет данных'
    }, null, 8, ["cols", "rows", "empty-banner-text"])
  ], 64))
}