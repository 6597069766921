
import { defineComponent } from 'vue';
import Activity from './components/activity.vue';
import Inspectors from './components/inspectors.vue';
// import Regime from './components/regime.vue';
import RegimeUgns from './components/regime-ugns.vue';
import Kkm from './components/kkm.vue';
import Territory from './components/territory.vue';
import BusinessType from './components/business-type.vue';

export default defineComponent({
  name: 'ReportDetail',
  components: {
    Activity,
    Inspectors,
    // Deprecated
    // Regime,
    RegimeUgns,
    Kkm,
    Territory,
    BusinessType,
  },
  data() {
    return {
      titles: {
        Activity: 'Отчет по видам деятельности',
        Inspectors: 'Отчет по инспекторам',
        // Deprecated
        // Regime: 'Общий отчет по налоговому режиму',
        RegimeUgns: 'Отчет по налоговому режиму в разрезе по УГНС',
        Kkm: 'Отчет по ККМ',
        Territory: 'Территориальный отчет по УГНС',
        BusinessType: 'Отчет по виду объекта',
      },
    };
  },
});
