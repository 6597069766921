import { Color } from 'chart.js';

export function getRandomColor(index: number, length: number): Color {
  const hue = Math.floor((index + 10 / length) * 341); // between 0 and 340
  let saturation = 100;
  let lightness = 50;

  // color adjustment:
  if (hue > 215 && hue < 265) {
    const gain = 20;
    const blueness = 1 - Math.abs(hue - 240) / 25;
    const change = Math.floor(gain * blueness);
    lightness += change;
    saturation -= change;
  }
  return `hsla(${hue}, ${saturation}%, ${lightness}%, 1)`;
}

export function getGroupColors(groupLength: number, mainColorHSLA: string): Color[] {
  const colors = [];
  for (let i = 1; groupLength >= i; i += 1) {
    if (i === 1) colors.push(mainColorHSLA);
    else colors.push(`${mainColorHSLA.slice(0, -2)}${1 - ((6 - i) / 10)})`);
  }
  return colors;
}
