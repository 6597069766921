
import {
  computed,
  defineComponent, reactive, watch,
} from 'vue';
import { useStore } from 'vuex';
import { ReportBusinessTypeByActivityRow } from '@/types/Reports';
import api from '@/api';
import useActivityTypes from '@/modules/useActivityTypes';
import useUgns, { UgnsOption } from '@/modules/useUgns';
import {
  Chart, FontSpec, TooltipModel,
} from 'chart.js';
import DoughnutChart from '@/views/reports/components/charts/DoughnutChart.vue';
import useIsKkm from '@/modules/useIsKkm';
import { toFont } from 'chart.js/helpers';
import { useRoute, useRouter } from 'vue-router';
import usePagination from '@/modules/usePagination';

export default defineComponent({
  components: {
    DoughnutChart,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const doughnutChartOptions = {
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          // Disable the on-canvas tooltip
          enabled: false,

          external(context: { chart: Chart; tooltip: TooltipModel<'doughnut'> }) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem: { before: string[]; lines: string[]; after: string[] }) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || [];
              const bodyLines = tooltipModel.body.map(getBody);

              let innerHtml = '<thead>';

              titleLines.forEach((title: string) => {
                innerHtml += `<tr><th>${title}</th></tr>`;
              });
              innerHtml += '</thead><tbody>';

              bodyLines.forEach((body, i: number) => {
                const colors = tooltipModel.labelColors[i];
                let style = `background:${colors.backgroundColor}`;
                style += `; border-color:${colors.borderColor}`;
                style += '; border-width: 2px';
                style += '; width: 1.2rem; height: 1.2rem';
                style += '; display: inline-block';
                style += '; vertical-align: middle';
                style += '; margin-right: 1rem;';
                style += '; margin-top: -0.2rem;';
                const span = `<span style="${style}"></span>`;
                innerHtml += `<tr><td>${span}${body}</td></tr>`;
              });
              innerHtml += '</tbody>';

              const tableRoot = tooltipEl.querySelector('table');
              if (tableRoot) {
                tableRoot.innerHTML = innerHtml;
              }
            }

            const position = context.chart.canvas.getBoundingClientRect();
            const bodyFont = toFont(tooltipModel.options.bodyFont as Partial<FontSpec>);

            // Display, position, and set styles for font
            tooltipEl.style.opacity = '1';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
            tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
            tooltipEl.style.font = bodyFont.string;
            tooltipEl.style.padding = `${tooltipModel.options.padding}px ${tooltipModel.options.padding}px`;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.width = 'auto';
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.8)';
            tooltipEl.style.border = '1px solid rgba(0, 0, 0, 0)';
            tooltipEl.style.color = '#fff';
          },
        },
      },
      cutout: '70%',
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          ticks: {
            display: false,
          },
        },
        y: { stacked: false },
      },
      plugins: { legend: { display: false } },
    };

    const { activityTypes } = useActivityTypes();
    activityTypes.fetch(false);

    const { ugns: ugnsSelector } = useUgns();
    ugnsSelector.fetch();

    const { isKkm: isKkmSelector } = useIsKkm();

    interface CurrentFilters extends Record<string, unknown>{
      activityId: number | null
      ugnsId: number | null
    }
    const report = usePagination({
      filters: {
        activityId: null,
        ugnsId: null,
      } as CurrentFilters,
      immediateFilters: ['activityId', 'ugnsId'],
      delayedFilters: ['locality', 'inspector'],
      rows: [] as ReportBusinessTypeByActivityRow[],
      async fetchContents() {
        const {
          activityId, ugnsId,
        } = (report.filters as CurrentFilters);
        const params = {
          ...report.pagination.params,
          pageSize: 100,
          ...activityId && { activityId },
          ...ugnsId && { ugnsId },
        };
        return (await api.reports.getBusinessType(params)).data;
      },
      async downloadExcel() {
        const {
          activityId, ugnsId,
        } = (report.filters as CurrentFilters);
        const params = {
          ...activityId && { activityId },
          ...ugnsId && { ugnsId },
        };
        const button = document.getElementById('button') as HTMLButtonElement | null;
        if (button !== null) {
          // eslint-disable-next-line no-unused-expressions
          button.setAttribute('disabled', 'disabled');
          button.innerHTML = 'Идет загрузка...';
          const disableButton = 60000;
          setTimeout(() => {
            // eslint-disable-next-line no-unused-expressions
            button.removeAttribute('disabled');
            button.innerHTML = 'Выгрузить отчёт в формате Excel';
          }, disableButton);
        }
        await api.reports.getExcelBusinessType(params);
      },
    });
    report.fetch();
    const paginateHandler = (pageNumber: number) => {
      report.pagination.page = pageNumber - 1;
      report.fetch(false, true);
    };

    const pagination = reactive({
      pages: computed(() => report.pagination.totalPages),
      currentPage: computed(() => report.pagination.page + 1),
      fetching: computed(() => report.fetching),
    });
    if (store.state.auth.isTno) {
      watch(() => ugnsSelector.options, () => {
        const userId = store.state.auth.userInfo.ugnsTno.id;
        report.filters.ugnsId = ugnsSelector.options.find((option: UgnsOption) => option.id === userId)?.id || null;
      });
    }
    const colsList = [
      {
        label: 'УГНС',
        component: (row: ReportBusinessTypeByActivityRow) => ({
          name: 'router-link',
          value: row.ugnsTno,
          class: 'link',
          to: { name: 'registry-tax-payers-payer-id', params: { payerId: row.id } },
        }),
      },
      {
        label: 'Вид объекта',
        display: (row: ReportBusinessTypeByActivityRow) => row.businessType,
      },
      {
        label: 'ИНН',
        display: (row: ReportBusinessTypeByActivityRow) => row.inn,
      },
      {
        label: 'Юридическое наименование',
        display: (row: ReportBusinessTypeByActivityRow) => row.subBusinessName,
      },
      {
        label: 'Торговое наименование',
        display: (row: ReportBusinessTypeByActivityRow) => row.objectName,
      },
      {
        label: 'СНО',
        display: (row: ReportBusinessTypeByActivityRow) => row.nalogRegime,
      },
      {
        label: 'Адрес',
        display: (row: ReportBusinessTypeByActivityRow) => row.tradeObject,
      },
      {
        label: 'Номер РНМ',
        display: (row: ReportBusinessTypeByActivityRow) => row.kkmModels,
      },
      {
        label: 'Торговая площадь',
        display: (row: ReportBusinessTypeByActivityRow) => row.area,
      },
      {
        label: 'ФИО инспектора',
        display: (row: ReportBusinessTypeByActivityRow) => row.inspector?.fio || 'Не назначен',
      },
    ];
    const isDetail = computed(() => !!(report.filters as CurrentFilters).activityId);
    const cols = computed(() => colsList);

    function filterFromQuery() {
      const {
        activityId, ugnsId,
      } = route.query as { activityId?: string, ugnsId?: string};
      const filters: Partial<CurrentFilters> = {
        activityId: activityId ? +activityId : null, ugnsId: ugnsId ? +ugnsId : null,
      };
      Object.assign(report.filters, filters);
      if (!report.fetching) report.fetch();
    }
    watch(
      () => [route.query],
      filterFromQuery,
      { immediate: true },
    );
    function filterToQuery(filters: {activityId?: number | null, ugnsId?: number | null }) {
      // добавляет в query переданные значения
      // удаляет из query значения, которые переданы как null | void
      // eslint-disable-next-line no-param-reassign
      const method = Object.keys(filters).includes('activityId') ? 'push' : 'replace';
      router[method]({ query: { ...route.query, ...filters as Record<string, string | undefined> } });
    }

    return {
      doughnutChartOptions,
      cols,
      isDetail,
      report,
      activityTypes,
      ugnsSelector,
      isKkmSelector,
      options,
      paginateHandler,
      pagination,
      filterToQuery,
    };
  },
});
