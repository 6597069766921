/* eslint-disable no-param-reassign */
import {
  reactive, watch,
} from 'vue';
import debounce from 'lodash.debounce';
import { ChartData } from 'chart.js';

export interface FiltersManagerProps extends Record<string, unknown>{
  filters: unknown & Record<string, unknown>
  immediateFilters: string[]
  delayedFilters: string[]
  rows: unknown[]
  chartData?: ChartData
  fetchContents(): Promise<{ rows: unknown[], chartData?: ChartData }>
}

export interface FiltersManager extends FiltersManagerProps {
  fetching: boolean
  fetch(): void
  filtersAreSet: boolean
}

export default function useFilters<T>(props: T & FiltersManagerProps): FiltersManager & T {
  const manager: FiltersManager = Object.assign(reactive(props), {
    fetching: false,
    async fetch() {
      manager.fetching = true;
      try {
        const { rows, chartData } = (await props.fetchContents.call(this));
        manager.rows = rows;
        manager.chartData = chartData;
      } catch (e) {
        console.log(e);
      } finally {
        manager.fetching = false;
      }
    },
    get filtersAreSet() {
      return !!Object.values(props.filters).filter((item) => item !== null && item !== '' && item !== 0).length;
    },
  });

  const delayedFetch = debounce(() => {
    manager.rows = [];
    manager.chartData = {
      labels: [],
      datasets: [{ data: [] }],
    };
    manager.fetch();
  }, 400);
  watch(
    () => manager.delayedFilters.map((key: string) => manager.filters[key]),
    () => {
      manager.rows = [];
      manager.chartData = {
        labels: [],
        datasets: [{ data: [] }],
      };
      manager.fetching = true;
      delayedFetch();
    },
    { deep: true },
  );
  watch(
    () => manager.immediateFilters.map((key: string) => manager.filters[key]),
    () => {
      delayedFetch.cancel();
      manager.chartData = {
        labels: [],
        datasets: [{ data: [] }],
      };
      manager.rows = [];
      manager.fetch();
    },
    { deep: true },
  );

  return manager as FiltersManager & T;
}
