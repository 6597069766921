
import {
  defineComponent, watch,
} from 'vue';
import { useStore } from 'vuex';
import api from '@/api';
import useFilters from '@/modules/useFilters';
import useUgns, { UgnsOption } from '@/modules/useUgns';
import { ReportInspectorRow } from '@/types/Reports';

export default defineComponent({
  setup() {
    const { ugns: ugnsSelector } = useUgns();
    ugnsSelector.fetch();

    const store = useStore();

    interface InspectorRow {
      ugns: string;
      inspector: string;
      nalogPayerCount: number;
      notprocessed: number;
      processed: number;
      liquidated: number;
    }
    interface CurrentFilters extends Record<string, unknown>{
      ugnsId: UgnsOption,
      locality: string,
      inspector: string,
    }
    const report = useFilters({
      filters: {
        ugnsId: ugnsSelector.selected,
        locality: '',
        inspector: '',
      } as CurrentFilters,
      immediateFilters: ['ugnsId'],
      delayedFilters: ['locality', 'inspector'],
      rows: [] as InspectorRow[],
      getParams() {
        const { ugnsId: ugns, locality, inspector } = (report.filters as CurrentFilters);
        const ugnsId = ugns?.id || 0;
        const params = {
          ...ugnsId && { ugnsId },
          ...locality && { locality },
          ...inspector && { inspector },
        };
        return params;
      },
      async fetchContents() {
        const rowsRes: ReportInspectorRow[] = (await api.reports.getInspectors(report.getParams())).data;
        const rows = rowsRes.map((row) => (row.inspectors.length ? row.inspectors.map((item, index) => {
          const {
            inspector,
            nalogPayerCount,
            notprocessed,
            // inwork,
            processed,
            liquidated,
          } = item;
          return {
            ugns: index ? '' : row.ugns.ugnsTnoCode,
            inspector,
            nalogPayerCount,
            notprocessed,
            // inwork,
            processed,
            liquidated,
          };
        }) : [{ ugns: row.ugns.ugnsTnoCode, inspector: 'Нет инспекторов', nalogPayerCount: 0 }]))
          .flat();
        return { rows };
      },
      async downloadExcel() {
        await api.reports.getExcelInspectors(report.getParams());
      },
    });
    report.fetch();

    if (store.state.auth.isTno) {
      watch(() => ugnsSelector.options, () => {
        const userId = store.state.auth.userInfo.ugnsTno.id;
        report.filters.ugnsId = ugnsSelector.options.find((option: UgnsOption) => option.id === userId) || {} as UgnsOption;
      });
    }

    const cols = [
      {
        label: 'УГНС',
        display: (row: InspectorRow) => row.ugns,
      },
      {
        label: 'ФИО инспектора',
        display: (row: InspectorRow) => row.inspector,
      }, {
        label: ' НП',
        display: (row: InspectorRow) => row.nalogPayerCount,
      },
      {
        label: 'Не обработано',
        display: (row: InspectorRow) => row.notprocessed,
      },
      // {
      //   label: 'В работе',
      //   display: (row: InspectorRow) => row.inwork,
      // },
      {
        label: 'Обработано',
        display: (row: InspectorRow) => row.processed,
      },
      {
        label: 'Ликвидировано',
        display: (row: InspectorRow) => row.liquidated,
      },
    ];

    return {
      cols,
      report,
      ugnsSelector,
    };
  },
});
