import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page__controls" }
const _hoisted_2 = {
  key: 0,
  class: "defaultLoader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiSelector = _resolveComponent("UiSelector")!
  const _component_UiTable = _resolveComponent("UiTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UiSelector, {
        placeholder: "Выбрать вид деятельности",
        options: _ctx.activityTypes.options,
        modelValue: _ctx.activityTypes.options.find(item => item?.id === _ctx.report.filters.activityId),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterToQuery({activityId: $event?.value || null, isKkm: null, inspector: '' }))),
        style: {"width":"30.5rem"}
      }, null, 8, ["options", "modelValue"]),
      _createVNode(_component_UiSelector, {
        placeholder: "Выбрать УГНС",
        options: _ctx.ugnsSelector.options,
        modelValue: _ctx.ugnsSelector.options.find(item => item?.id === _ctx.report.filters.ugnsId),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterToQuery({ugnsId: $event?.id}))),
        style: {"width":"20rem"},
        disabled: _ctx.$store.state.auth.isTno
      }, null, 8, ["options", "modelValue", "disabled"]),
      _createElementVNode("button", {
        class: "download-button",
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.report.downloadExcel()), ["prevent"])),
        id: "button"
      }, "Выгрузить отчёт в формате Excel")
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["page__charts", {'_page__charts--short': _ctx.report.rows.length < 3}])
    }, [
      (!_ctx.isDetail && _ctx.report.rows.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_UiTable, {
      onChangePage: _ctx.paginateHandler,
      pagination: _ctx.pagination,
      cols: _ctx.cols,
      rows: _ctx.report.rows,
      "empty-banner-text": _ctx.report.fetching ? 'Идет загрузка' : 'Нет данных'
    }, {
      default: _withCtx(() => [
        (_ctx.report.fetching)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Идет загрузка..."))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onChangePage", "pagination", "cols", "rows", "empty-banner-text"])
  ], 64))
}