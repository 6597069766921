import { reactive } from 'vue';

export interface IsKkmOption {
  name: string | undefined,
  value: boolean | null,
  id: boolean | null,
}
export default function useIsKkm() {
  const isKkm = reactive({
    selected: {} as IsKkmOption,
    options: [
      { name: 'Не выбрано', value: null, id: null },
      { name: 'С ККМ', value: true, id: true },
      { name: 'Без ККМ', value: false, id: false },
    ] as IsKkmOption[] | null | void,
  });

  return { isKkm };
}
