import { reactive } from 'vue';
import api from '@/api';

export interface NalogRegimeOption {
  name: string | undefined,
  value: number | undefined,
  id: number | undefined,
}
export default function useNalogRegime() {
  const nalogRegimeSelector = reactive({
    selected: {} as NalogRegimeOption,
    options: [] as NalogRegimeOption[] | undefined,
    async fetch() {
      try {
        const resp = await api.nalogRegime.findAll();
        nalogRegimeSelector.options = [{ name: 'Не выбрано', id: 0, value: undefined }];
        nalogRegimeSelector.options.push(...resp.data.map((item) => ({ name: item.subType || item.type || item.name, value: item.id, id: item.id })));
      } catch (e) {
        console.log(e);
      }
    },
  });

  return { nalogRegimeSelector };
}
