import { reactive } from 'vue';

export interface ChoiceTypeOption {
  name: string,
  value: boolean | null,
  id: boolean | null,
}

export default function useChoiceTypes() {
  const isChoiceType = reactive({
    selected: {} as ChoiceTypeOption,
    options: [
      { name: 'По виду деятельности ГКЭД', value: true, id: true },
      { name: 'По виду объекта', value: false, id: false },
    ] as ChoiceTypeOption[] | null,
  });

  return { isChoiceType };
}
