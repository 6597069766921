
import {
  computed, defineComponent, reactive, watch,
} from 'vue';
import { useStore } from 'vuex';
import {
  Chart,
  ChartData, FontSpec, TooltipModel,
} from 'chart.js';
import { toFont } from 'chart.js/helpers';
import { ReportActivityRow, UgnsReportActivityRow } from '@/types/Reports';
import api from '@/api';
import useFilters from '@/modules/useFilters';
import useActivityTypes, { ActivityTypeOption } from '@/modules/useActivityTypes';
import useUgns, { UgnsOption } from '@/modules/useUgns';
import DoughnutChart from '@/views/reports/components/charts/DoughnutChart.vue';
import { getRandomColor } from '@/utils/randomColor';
import { numberWithSpaces } from '@/utils';

export default defineComponent({
  components: {
    DoughnutChart,
  },
  setup() {
    const ugnsColumns = reactive({
      values: [] as Record<string, unknown>[],
    });

    const store = useStore();

    const doughnutChartOptions = {
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          // Disable the on-canvas tooltip
          enabled: false,

          external(context: { chart: Chart; tooltip: TooltipModel<'doughnut'> }) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem: { before: string[]; lines: string[]; after: string[] }) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || [];
              const bodyLines = tooltipModel.body.map(getBody);

              let innerHtml = '<thead>';

              titleLines.forEach((title: string) => {
                innerHtml += `<tr><th>${title}</th></tr>`;
              });
              innerHtml += '</thead><tbody>';

              bodyLines.forEach((body, i: number) => {
                const colors = tooltipModel.labelColors[i];
                let style = `background:${colors.backgroundColor}`;
                style += `; border-color:${colors.borderColor}`;
                style += '; border-width: 2px';
                style += '; width: 1.2rem; height: 1.2rem';
                style += '; display: inline-block';
                style += '; vertical-align: middle';
                style += '; margin-right: 1rem;';
                style += '; margin-top: -0.2rem;';
                const span = `<span style="${style}"></span>`;
                innerHtml += `<tr><td>${span}${body}</td></tr>`;
              });
              innerHtml += '</tbody>';

              const tableRoot = tooltipEl.querySelector('table');
              if (tableRoot) {
                tableRoot.innerHTML = innerHtml;
              }
            }

            const position = context.chart.canvas.getBoundingClientRect();
            const bodyFont = toFont(tooltipModel.options.bodyFont as Partial<FontSpec>);

            // Display, position, and set styles for font
            tooltipEl.style.opacity = '1';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
            tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
            tooltipEl.style.font = bodyFont.string;
            tooltipEl.style.padding = `${tooltipModel.options.padding}px ${tooltipModel.options.padding}px`;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.width = 'auto';
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.8)';
            tooltipEl.style.border = '1px solid rgba(0, 0, 0, 0)';
            tooltipEl.style.color = '#fff';
          },
        },
      },
      cutout: '70%',
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
    };

    const { activityTypes } = useActivityTypes();
    activityTypes.fetch(true);

    const { ugns: ugnsSelector } = useUgns();
    ugnsSelector.fetch();

    interface CurrentFilters extends Record<string, unknown>{
      activityId: ActivityTypeOption,
      locality: string,
      ugnsId: UgnsOption,
    }
    const report = useFilters({
      filters: {
        activityId: activityTypes.selected,
        locality: '',
        ugnsId: ugnsSelector.selected,
      } as CurrentFilters,
      immediateFilters: ['activityId', 'ugnsId'],
      delayedFilters: ['locality'],
      rows: [] as ReportActivityRow[],
      chartData: {
        labels: [],
        datasets: [{ data: [], backgroundColor: [], barPercentage: 0.5 }],
      } as ChartData,
      getParams() {
        const { locality, ugnsId: ugns, activityId: activity } = (report.filters as CurrentFilters);
        const activityId = activity?.id || 0;
        const ugnsId = ugns?.id || 0;
        const params = {
          ...activityId && { activityId },
          ...ugnsId && { ugnsId },
          ...locality && { locality },
        };
        return params;
      },
      async fetchContents() {
        const chartData = {
          labels: [],
          datasets: [{ data: [], backgroundColor: [], barPercentage: 0.5 }],
        } as ChartData;

        const rows: ReportActivityRow[] = (await api.reports.getActivities(report.getParams())).data;
        // добавить нужные колонки
        ugnsColumns.values = [];
        const notSortedChartData: {name: string; value: number}[] = [];
        const summaryRow = {
          sumNalogPayers: 0,
          activityType: {
            value: 'Итого (по всем видам деятельности)',
          },
          ugns: [] as UgnsReportActivityRow[],
        } as ReportActivityRow;
        rows.forEach((row) => {
          notSortedChartData.push({ name: row.activityType.value, value: row.sumNalogPayers });
          // eslint-disable-next-line no-shadow
          row.ugns.forEach((ugns) => {
            const { name } = ugns.ugnsTno;
            // eslint-disable-next-line no-shadow
            const ugnsIndex = summaryRow.ugns.findIndex((ugns) => ugns.ugnsTno.name === name);
            if (ugnsIndex !== -1) {
              summaryRow.ugns[ugnsIndex].countNalogPayers += ugns.countNalogPayers || 0;
              summaryRow.sumNalogPayers += ugns.countNalogPayers || 0;
            } else {
              summaryRow.sumNalogPayers += ugns.countNalogPayers || 0;
              summaryRow.ugns.push({
                ugnsTno: {
                  name,
                },
                countNalogPayers: ugns.countNalogPayers || 0,
              } as UgnsReportActivityRow);
            }

            if (ugnsColumns.values.some((col) => col.label === name)) return;
            ugnsColumns.values.push({
              label: name,
              // eslint-disable-next-line no-shadow
              display: (activityRow: ReportActivityRow) => numberWithSpaces(activityRow.ugns.find((ugns) => ugns.ugnsTno.name === name)?.countNalogPayers || '0'),
            });
          });
        });
        notSortedChartData.sort((a, b) => {
          if (a.value < b.value) return 1;
          if (a.value > b.value) return -1;
          return 0;
        });
        notSortedChartData.slice(0, 29).forEach((elem, i, a) => {
          // eslint-disable-next-line no-unused-expressions
          chartData.labels && chartData.labels.push(elem.name);
          chartData.datasets[0].data.push(elem.value);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line
          chartData && chartData.datasets[0] && chartData.datasets[0].backgroundColor && chartData.datasets[0].backgroundColor.push(getRandomColor(i, a.length));
        });
        if (rows.length > 1) rows.unshift(summaryRow);
        return { rows, chartData };
      },
      async downloadExcel() {
        await api.reports.getExcelActivities(report.getParams());
      },
    });
    report.fetch();

    if (store.state.auth.isTno) {
      watch(() => ugnsSelector.options, () => {
        const userId = store.state.auth.userInfo.ugnsTno.id;
        report.filters.ugnsId = ugnsSelector.options.find((option: UgnsOption) => option.id === userId) || {} as UgnsOption;
      });
    }

    const colsSlider = reactive({
      index: 0,
      visibleCount: 4,
      getVisibleCols(): Record<string, unknown>[] {
        const ugnsCols = ugnsColumns.values;
        const { length } = ugnsCols;
        if (length <= this.visibleCount) return ugnsCols;

        const result = ugnsCols.slice(this.index, this.index + this.visibleCount).map((row) => ({
          ...row, width: '12%', slideLeft: false, slideRigth: false,
        }));

        if (result[0] && this.index > 0) result[0].slideLeft = true;

        const lastIndex = this.visibleCount - 1;
        if (result[lastIndex]) result[lastIndex].slideRigth = true;

        return result;
      },
    });

    const cols = computed(() => {
      const result = [
        {
          label: 'Вид деятельности',
          width: '40%',
          display: (row: ReportActivityRow) => numberWithSpaces(row.activityType.value),
        },
        ...colsSlider.getVisibleCols(),
        {
          label: 'Итого НП',
          width: '12%',
          display: (row: ReportActivityRow) => numberWithSpaces(row.sumNalogPayers),
        },
      ];
      return result;
    });

    const statistic = computed<number>(() => (report.rows as ReportActivityRow[]).reduce((acc, curr, i) => {
      if (i === 0) return 0;
      return (acc + curr.sumNalogPayers);
    }, 0));

    return {
      activityTypes,
      ugnsSelector,
      cols,
      colsSlider,
      report,
      statistic,
      doughnutChartOptions,
      numberWithSpaces,
    };
  },
});
