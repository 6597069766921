
import {
  defineComponent, watch,
} from 'vue';
import { useStore } from 'vuex';
import { ReportTerritoryRow } from '@/types/Reports';
import api from '@/api';
import useFilters from '@/modules/useFilters';
import useUgns, { UgnsOption } from '@/modules/useUgns';
import DoughnutChart from '@/views/reports/components/charts/DoughnutChart.vue';
import { ChartData } from 'chart.js';
import { getRandomColor } from '@/utils/randomColor';

export default defineComponent({
  components: {
    DoughnutChart,
  },
  setup() {
    const store = useStore();

    const { ugns: ugnsSelector } = useUgns();
    ugnsSelector.fetch();

    interface CurrentFilters extends Record<string, unknown>{
      ugnsId: UgnsOption,
      locality: string,
    }
    const report = useFilters({
      filters: {
        ugnsId: ugnsSelector.selected,
        locality: '',
      } as CurrentFilters,
      immediateFilters: ['ugnsId'],
      delayedFilters: ['locality'],
      rows: [] as ReportTerritoryRow[],
      chartData: {
        labels: [],
        datasets: [{ data: [], backgroundColor: [], barPercentage: 0.5 }],
      } as ChartData,
      getParams() {
        const { ugnsId: ugns, locality } = (report.filters as CurrentFilters);
        const ugnsId = ugns?.id || 0;
        const params = {
          ...ugnsId && { ugnsId },
          ...locality && { locality },
        };
        return params;
      },
      async fetchContents() {
        const rows: ReportTerritoryRow[] = (await api.reports.getTerritory(report.getParams())).data;

        const notSortedChartData: {name: string; value: number}[] = [];
        const chartData = {
          labels: [],
          datasets: [{ data: [], backgroundColor: [], barPercentage: 0.5 }],
        } as ChartData;

        rows.forEach((item) => {
          notSortedChartData.push({ name: item.ugnsTno?.ugnsTnoCode, value: item.countNalogPayers });
        });

        notSortedChartData.sort((a, b) => {
          if (a.value < b.value) return 1;
          if (a.value > b.value) return -1;
          return 0;
        });
        notSortedChartData.slice(0, 29).forEach((elem, i, a) => {
          // eslint-disable-next-line no-unused-expressions
          chartData.labels && chartData.labels.push(elem.name);
          chartData.datasets[0].data.push(elem.value);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line
          chartData && chartData.datasets[0] && chartData.datasets[0].backgroundColor && chartData.datasets[0].backgroundColor.push(getRandomColor(i, a.length));
        });
        // if (rows.length > 1) rows.unshift(summaryRow);
        return { rows, chartData };
      },
      async downloadExcel() {
        await api.reports.getExcelTerritory(report.getParams());
      },
    });
    report.fetch();

    if (store.state.auth.isTno) {
      watch(() => ugnsSelector.options, () => {
        const userId = store.state.auth.userInfo.ugnsTno.id;
        report.filters.ugnsId = ugnsSelector.options.find((option: UgnsOption) => option.id === userId) || {} as UgnsOption;
      });
    }

    const cols = [
      {
        label: 'УГНС',
        display: (row: ReportTerritoryRow) => row.ugnsTno.ugnsTnoCode,
      },
      {
        label: 'Итого НП',
        display: (row: ReportTerritoryRow) => row.countNalogPayers,
      },
      {
        label: 'Итого ККМ',
        field: 'countKkm',
      },
      {
        label: 'Не обработано',
        display: (row: ReportTerritoryRow) => row.notprocessed,
      },
      // {
      //   label: 'В работе',
      //   display: (row: ReportTerritoryRow) => row.inwork,
      // },
      {
        label: 'Обработано',
        display: (row: ReportTerritoryRow) => row.processed,
      },
      {
        label: 'Ликвидировано',
        display: (row: ReportTerritoryRow) => row.liquidated,
      },
    ];

    return {
      cols,
      ugnsSelector,
      report,
    };
  },
});
